/**
 * Created by andrew.stalker on 22/11/2018.
 * Scripts for handling cask event groups and event codes.
 */

/* globals findObjectInArray */

/**
 * @typedef {Object} eventGroup
 * @property {String} groupCode - Unique code identifying the event group
 * @property {String} Name - Friendly name to display for this event group
 * @property {event[]} events - events belonging to this event group
 */

/**
 * @typedef {Object} event
 * @property {String} code - Unique event code
 * @property {String} name - Friendly name for the event
 */

/**
 * @description Saves event groups to LS
 * @param {Array.<eventGroup>} eventGroups - Array of groups to be saved
 */
function saveEventGroupsToStorage(eventGroups) {
	"use strict";
	localStorage.setObject("data/eventGroups",eventGroups);
}

/**
 * @description Retrieves the event groups from LS
 */
function getEventGroupsFromStorage() {
	"use strict";
	return localStorage.getObject("data/eventGroups");
}

/**
 * @description Retrieves a given event group
 * @param {eventGroup|String} eventGroupCode
 * @returns {eventGroup|null}
 */
function getEventGroup(eventGroupCode) {
	"use strict";
	var allGroups = getEventGroupsFromStorage();
	/** @type {eventGroup||null} */
	var group = findObjectInArray(allGroups,"groupCode",eventGroupCode);
	return (group)? group : null;
}

/**
 * @description Retrieves events for a given event group
 * @param {eventGroup.groupCode|String} eventGroupCode
 * @returns {event[]|[]}
 */
function getEventsForEventGroup(eventGroupCode) {
	"use strict";
	var allGroups = getEventGroupsFromStorage();
	/** @type {eventGroup||null} */
	var group = findObjectInArray(allGroups,"groupCode",eventGroupCode);
	return (group)? group.events : [];
}

/**
 * @description Finds and returns an event
 * @param {eventGroup.groupCode|String} eventGroupCode - Group the event belongs to
 * @param {event.code|String} eventCode - Event code for the event required
 * @returns {event|*|null}
 */
function getEvent(eventGroupCode,eventCode) {
  "use strict";
	/** @type {Array.<events>} */
	var filteredEvents = getEventsForEventGroup(eventGroupCode).filter(function(el) {
		return el.code === eventCode;
	});
	return filteredEvents[0] || null;
}

function getCaskEvents(caskId, callback) {
	"use strict";
	var scanner = getScannerId(),
		company = getScannerCompany();

	$.ajax({
		url: getRequestPath("cask/"+caskId+"/history?coyno=" + company + "&scanner=" + scanner),
		type: "GET",
		contentType: "application/JSON",
		timeout: requestTimeout,
		xhrFields: {withCredentials: true},
		success: function (data,status,xhr) {
			switch (xhr.status) {
				case 200:
					//Good - check response
					callback(true, data);
					break;
				case 404:
					//Not found - the request was successful though
					callback(false, 404);
					break;
				case 401:
					if (!attemptLoginRequestDone) {
						attemptLoginRequestDone = true;
						renewServerSession(function (valid) {
							if (valid) {
								//call self again.
								getCaskEvents(caskId, callback);
							} else {
								callback(false, 401); // renew session was unsuccessful
							}
						});
					} else {
						callback(false, 401); // Already tried to renew server session
					}
					break;
				default:
					callback(false, xhr.status);//bad - the request failed for some reason
					break;
			}
		},
		error: function (xhr) {
			switch (xhr.status) {
				case 404:
					//Not found - the request was successful though
					callback(false, 404);
					break;
				case 401:
					if (!attemptLoginRequestDone) {
						attemptLoginRequestDone = true;
						renewServerSession(function (valid) {
							if (valid) {
								//call self again.
								getCaskEvents(caskId, callback);
							} else {
								callback(false, 401); // renew session was unsuccessful
							}
						});
					} else {
						callback(false, 401); // Already tried to renew server session
					}
					break;
				default:
					callback(false, xhr.status);//bad - the request failed for some reason
					break;
			}
		}
	});
}